<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <router-link
          :to="{ name: 'ListVersions' }"
          class="nav-link"
          v-bind:class="{ active: $route.name === 'ListVersions' }"
        >
          <v-btn icon>
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
        </router-link>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-toolbar-title v-if="!hideAddVersionNumberPanel"
          >New Version</v-toolbar-title
        >
        <v-toolbar-title v-if="hideAddVersionNumberPanel"
          >New Version {{ version.version }}</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text>
        <v-container v-if="!hideAddVersionNumberPanel">
          <v-row align="center" justify="center">
            <v-col cols="3">
              <v-text-field
                v-model="version.version"
                label="Version Number"
                :disabled="$route.params.id != undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="platform"
                :items="this.platforms"
                :disabled="$route.params.id != undefined"
                item-text="name"
                item-value="id"
                return-object
                filled
                label="Platform"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-btn
                color="primary"
                darken-1
                class="mb-2"
                :disabled="
                  version.version == null ||
                  version.version == '' ||
                  Object.keys(platform).length === 0
                "
                @click="saveVersionNumber"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="showAddFeaturesPanel">
          <v-tabs centered v-model="tab">
            <v-tab
              v-for="language in platform.languages"
              :key="'tab_' + language.code"
            >
              <v-badge color="accent-4" offset-x="10" offset-y="10">
                {{ language.code }}
                <v-avatar size="30">
                  <v-img :src="language.flag"></v-img>
                </v-avatar>
              </v-badge>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-8">
            <v-tab-item
              v-for="language in platform.languages"
              :key="'tab_content_' + language.code"
            >
              <v-row align="center" justify="center">
                <v-col cols="3">
                  <v-text-field
                    v-model="default_feature.translations[language.code].title"
                    label="Title"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-file-input
                    v-model="default_feature.translations[language.code].media"
                    label="Media"
                    filled
                    accept="video/mp4, image/jpg, image/jpeg, image/png"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="6">
                  <ckeditor
                    :key="`key_default_feature_content_${language.id}`"
                    v-model="
                      default_feature.translations[language.code].content
                    "
                    :config="getCKEditorConfig(language.code)"
                  ></ckeditor>
                </v-col>
              </v-row>
            </v-tab-item>
            <div class="text-center">
              <v-btn
                color="primary"
                darken-1
                class="mb-2 mt-2"
                :disabled="checkIfEmptyDefaultFeature"
                @click="saveFeature"
              >
                Add Feature
              </v-btn>
            </div>
          </v-tabs-items>
          <v-expansion-panels
            v-if="version.features.length != 0"
            v-model="panel"
            class="mb-6 mt-6"
          >
            <v-expansion-panel
              v-for="(feature, index) in version.features"
              :key="feature + index"
            >
              <v-expansion-panel-header
                >Feature {{ index + 1 }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-tabs centered v-model="feature.tab">
                  <v-tab
                    v-for="language in platform.languages"
                    :key="`tab_${index}${language.code}`"
                  >
                    <v-badge color="accent-4" offset-x="10" offset-y="10">
                      {{ language.code }}
                      <v-avatar size="30">
                        <v-img :src="language.flag"></v-img>
                      </v-avatar>
                    </v-badge>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="feature.tab" class="mt-8">
                  <v-tab-item
                    v-for="language in platform.languages"
                    :key="`tab_content_${index}_${language.code}`"
                  >
                    <v-row align="center" justify="center">
                      <v-col cols="3">
                        <v-text-field
                          v-model="feature.translations[language.code].title"
                          label="Title"
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-file-input
                          v-model="feature.translations[language.code].media"
                          label="Media"
                          filled
                          accept="video/*, image/*"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="3">
                        <ckeditor
                          :key="`key_feature_content_${index}_${language.id}`"
                          v-model="feature.translations[language.code].content"
                          :config="getCKEditorConfig(language.code)"
                        ></ckeditor>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-btn
            color="primary"
            darken-1
            class="mb-2"
            :disabled="version.features.length == 0"
            :loading="saveVersionLoading"
            @click="saveVersion"
          >
            Save Version
          </v-btn>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_PLATFORMS } from "@/store/options.module";
import {
  CREATE_VERSION,
  GET_VERSION,
  UPDATE_VERSION,
} from "@/store/versions.module";
import CKEditor from "ckeditor4-vue";

export default {
  name: "Form",
  components: {
    ckeditor: CKEditor.component,
  },
  mounted() {
    this.getPlatforms();

    if (this.$route.params.id) {
      this.getVersion(this.$route.params.id);
    }
  },
  data: () => ({
    hideAddVersionNumberPanel: false,
    showAddFeaturesPanel: false,
    version: {
      version: null,
      platform_id: null,
      features: [],
    },
    platform: {},
    default_feature: {
      translations: {},
    },
    tab: null,
    panel: null,

    saveVersionLoading: false,

    editorConfig: {},
  }),
  computed: {
    ...mapState(["platforms"]),
    checkIfEmptyDefaultFeature() {
      let disable = false;
      this.platform.languages.forEach((platformLanguage) => {
        if (
          this.default_feature.translations[platformLanguage.code].title ===
            null ||
          this.default_feature.translations[platformLanguage.code].title ===
            "" ||
          this.default_feature.translations[platformLanguage.code].content ===
            null ||
          this.default_feature.translations[platformLanguage.code].content ===
            ""
        ) {
          disable = true;
        }
      });

      return disable;
    },
  },
  watch: {},
  created() {},
  methods: {
    makeActive(id) {
      this.$store.dispatch(GET_PLATFORMS, id);
    },

    getVersion(id) {
      this.$store.dispatch(GET_VERSION, id).then((response) => {
        this.version = response.data.data;
        this.platform = this.version.platform;
      });
    },

    getPlatforms() {
      this.$store.dispatch(GET_PLATFORMS);
    },
    emptyDefaultFeatures() {
      let featureTranslations = {};
      this.platform.languages.forEach((platformLanguage) => {
        featureTranslations[platformLanguage.code] = {};
        featureTranslations[platformLanguage.code].title = null;
        featureTranslations[platformLanguage.code].language_id =
          platformLanguage.id;
        featureTranslations[platformLanguage.code].media = null;
        featureTranslations[platformLanguage.code].content = null;
      });
      this.default_feature.translations = featureTranslations;
    },
    getCKEditorConfig(languageCode) {
      let editorSettingsTranslations = {};
      this.platform.languages.forEach((platformLanguage) => {
        editorSettingsTranslations[platformLanguage.code] = {};
        editorSettingsTranslations[platformLanguage.code].toolbar = [
          { name: "basicstyles", items: ["Bold", "Italic", "BulletedList"] },
        ];
      });

      return editorSettingsTranslations[languageCode];
    },
    saveVersionNumber() {
      this.hideAddVersionNumberPanel = true;
      this.showAddFeaturesPanel = true;
      this.version.platform_id = this.platform.id;
      //Create object with paltforms translations
      this.emptyDefaultFeatures();
    },
    saveFeature() {
      this.version.features.push({
        ...this.default_feature,
        tab: null,
      });
      this.emptyDefaultFeatures();
    },
    saveVersion() {
      let isEditPage = this.$route.params.id;
      this.saveVersionLoading = true;

      let formData = new FormData();
      formData.append("version", this.version.version);
      formData.append("platform_id", this.version.platform_id);

      this.version.features.forEach((feature, featureIndex) => {
        for (const translationIndex in feature.translations) {
          let translation = feature.translations[translationIndex];

          if (isEditPage) {
            formData.append(`features[${featureIndex}][id]`, feature.id);
            formData.append(
              `features[${featureIndex}][translations][${translationIndex}][id]`,
              translation.id
            );
          }

          formData.append(
            `features[${featureIndex}][translations][${translationIndex}][title]`,
            translation.title
          );
          formData.append(
            `features[${featureIndex}][translations][${translationIndex}][language_id]`,
            translation.language_id
          );
          if (translation.media != null) {
            formData.append(
              `features[${featureIndex}][translations][${translationIndex}][media]`,
              translation.media
            );
          }
          formData.append(
            `features[${featureIndex}][translations][${translationIndex}][content]`,
            translation.content
          );
        }
      });

      let action;
      let data = {};

      if (isEditPage) {
        action = UPDATE_VERSION;
        formData.append("_method", "PUT");
        data.id = this.version.id;
      } else {
        action = CREATE_VERSION;
      }

      data.payload = formData;

      this.$store
        .dispatch(action, data)
        .then(() => this.$router.push({ name: "ListVersions" }));
    },
  },
};
</script>

<style scoped></style>
